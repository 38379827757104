<template>
  <el-main v-if="info">
    <div class="title">1、用户信息</div>
    <div class="row" style="margin:30px 0 30px 20px;">
        <el-image :src="info.avatar" class="avatar mr40"></el-image>
        <div class="mr40">{{info.nickname}}</div>
        <div class="mr40">用户姓名：--</div>
        <div class="mr40">手机号码：{{info.mobile}}</div>
        <div class="mr40">用户姓名：</div>
        <div>注册时间：{{info.create_time|getDateformat}}</div>
    </div>
    <div class="title">2、会员信息</div>
    <div class="row memberinfo" style="margin:30px 0 30px 20px;">
        用户身份：{{info.role_type | role_type}}
    </div>
    <div class="title">3、资产信息</div>
    <div style="margin:20px 0 10px 20px;font-weight: bold;">卡项优惠</div>
    <el-table :data="info.card_item_list" style="width: 90%;margin: 20px 0 30px 20px">
      <el-table-column prop="card_info.card_item_name" label="卡项名称" width="180" align="center">
      </el-table-column>
       <el-table-column prop="card_usable_sum" label="剩余次数" align="center">
            <template v-slot="{ row }">
            <span style="margin:0 10px;">{{row.card_usable_sum}}</span>
            <el-button type="text" @click="look(row)">查看</el-button>
           </template>
      </el-table-column>
       <el-table-column prop="due_time" label="最近次数到期时间" align="center">
           <template v-slot="{ row }">
            {{row.due_time | getDateformat2}}
           </template>
      </el-table-column>
    </el-table>
    <div class="title">4、消费信息</div>
    <el-table :data="consumption" style="width: 90%;margin: 30px 0 100px 20px;">
      <el-table-column prop="xf_time" label="最近下单时间" align="center">
      </el-table-column>
      <el-table-column prop="cost_price" label="客单价"  align="center">
      </el-table-column>
      <el-table-column prop="xf_amount" label="累计消费金额" align="center">
      </el-table-column>
       <el-table-column prop="xf_num" label="累计订单数" align="center">
      </el-table-column>
       <el-table-column prop="xf_appointment_num" label="预约到店次数" align="center">
      </el-table-column>
    </el-table>
    <!-- 次数记录 -->
    <el-dialog  title="次数纪录" :visible.sync="dialogVisible" width="50%">
        <div v-if="currentrow">卡项名称：{{currentrow.card_info.card_item_name}}</div>
        <el-table :data="log_list" style="margin: 20px 0 70px;" height="400px">
            <el-table-column prop="create_time" label="领取时间" align="center">
                 <template v-slot="{ row }">
                    {{row.create_time | getDateformat}}
                </template>
            </el-table-column>
            <el-table-column prop="usable_sum" label="次数"  align="center">
            </el-table-column>
            <el-table-column prop="xf_amount" label="领取/消耗方式" align="center">
                <template v-slot="{ row }">
                    {{row.type | handleType}}
                </template>
            </el-table-column>
            <el-table-column prop="due_time" label="到期时间" align="center">
                <template v-slot="{ row }">
                    {{row.due_time | getDateformat2}}
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
    data() {
        return {
            id: '',
            info:'',
            consumption:[],
            dialogVisible:false,
            currentrow:'',
            log_list:[]
        };
    },
    filters:{
        getDateformat:getDateformat,
        getDateformat2(val){
            return val?getDateformat(val).substr(0,10):'永久有效'
        },
        role_type(val){
            switch(val){
                case 2:
                    return '推广员'
                    break
                case 3:
                    return '创业股东'
                    break
                default:
                    return '普通用户'
                    break
            }
        },
        handleType(val){
            // 1卡项购买，2卡项充值，3套餐赠送，4预约扣除，5取消预约，6卡项出售，7充值自用'
            switch(val){
                case 2:
                    return '卡项充值'
                    break
                case 3:
                    return '套餐赠送'
                    break
                case 4:
                    return '预约扣除'
                    break
                case 5:
                    return '取消预约'
                    break
                case 6:
                    return '卡项出售'
                    break
                case 7:
                    return '充值自用'
                    break
                default:
                    return '卡项购买'
                    break
            }
        }
    },
    created() {
        this.getInfo()
    },
  methods: {
    getInfo(){
        this.$axios.post(this.$api.beauty.userInfo, {id:this.$route.query.id}).then(res => {
            if (res.code == 0) {
                this.info = res.result
                this.consumption = [this.info.consumption_information]
                console.log( this.consumption)
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    look(row){
        this.currentrow = row
        this.$axios.post(this.$api.beauty.cardItemDetailLog, {
            id:this.$route.query.id,
            card_item_id:row.card_item_id,
        }).then(res => {
            if (res.code == 0) {
                this.log_list = res.result
               this.dialogVisible = true
            } else {
                this.$message.error(res.msg);
            }
        });
    }
  },
};
</script>
<style lang="less" scoped>
    .el-main{
        background-color: #fff;
        font-size: 14px;
        .title{
            line-height: 45px;
            font-weight: bold;
        }
        .row{
            display: flex;
            align-items: center;
        }
        .avatar{
            width: 60px;
            height: 60px;
        }
        .mr40{
            margin-right: 40px;
        }
        .memberinfo{
            height: 80px;
            width: 90%;
            background-color: #f0f0f0;
            padding: 0 30px;
        }
    }
</style>
