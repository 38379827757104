var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info)?_c('el-main',[_c('div',{staticClass:"title"},[_vm._v("1、用户信息")]),_c('div',{staticClass:"row",staticStyle:{"margin":"30px 0 30px 20px"}},[_c('el-image',{staticClass:"avatar mr40",attrs:{"src":_vm.info.avatar}}),_c('div',{staticClass:"mr40"},[_vm._v(_vm._s(_vm.info.nickname))]),_c('div',{staticClass:"mr40"},[_vm._v("用户姓名：--")]),_c('div',{staticClass:"mr40"},[_vm._v("手机号码："+_vm._s(_vm.info.mobile))]),_c('div',{staticClass:"mr40"},[_vm._v("用户姓名：")]),_c('div',[_vm._v("注册时间："+_vm._s(_vm._f("getDateformat")(_vm.info.create_time)))])],1),_c('div',{staticClass:"title"},[_vm._v("2、会员信息")]),_c('div',{staticClass:"row memberinfo",staticStyle:{"margin":"30px 0 30px 20px"}},[_vm._v(" 用户身份："+_vm._s(_vm._f("role_type")(_vm.info.role_type))+" ")]),_c('div',{staticClass:"title"},[_vm._v("3、资产信息")]),_c('div',{staticStyle:{"margin":"20px 0 10px 20px","font-weight":"bold"}},[_vm._v("卡项优惠")]),_c('el-table',{staticStyle:{"width":"90%","margin":"20px 0 30px 20px"},attrs:{"data":_vm.info.card_item_list}},[_c('el-table-column',{attrs:{"prop":"card_info.card_item_name","label":"卡项名称","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"card_usable_sum","label":"剩余次数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"margin":"0 10px"}},[_vm._v(_vm._s(row.card_usable_sum))]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.look(row)}}},[_vm._v("查看")])]}}],null,false,3929645723)}),_c('el-table-column',{attrs:{"prop":"due_time","label":"最近次数到期时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getDateformat2")(row.due_time))+" ")]}}],null,false,3332618699)})],1),_c('div',{staticClass:"title"},[_vm._v("4、消费信息")]),_c('el-table',{staticStyle:{"width":"90%","margin":"30px 0 100px 20px"},attrs:{"data":_vm.consumption}},[_c('el-table-column',{attrs:{"prop":"xf_time","label":"最近下单时间","align":"center"}}),_c('el-table-column',{attrs:{"prop":"cost_price","label":"客单价","align":"center"}}),_c('el-table-column',{attrs:{"prop":"xf_amount","label":"累计消费金额","align":"center"}}),_c('el-table-column',{attrs:{"prop":"xf_num","label":"累计订单数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"xf_appointment_num","label":"预约到店次数","align":"center"}})],1),_c('el-dialog',{attrs:{"title":"次数纪录","visible":_vm.dialogVisible,"width":"50%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.currentrow)?_c('div',[_vm._v("卡项名称："+_vm._s(_vm.currentrow.card_info.card_item_name))]):_vm._e(),_c('el-table',{staticStyle:{"margin":"20px 0 70px"},attrs:{"data":_vm.log_list,"height":"400px"}},[_c('el-table-column',{attrs:{"prop":"create_time","label":"领取时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getDateformat")(row.create_time))+" ")]}}],null,false,2209289673)}),_c('el-table-column',{attrs:{"prop":"usable_sum","label":"次数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"xf_amount","label":"领取/消耗方式","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("handleType")(row.type))+" ")]}}],null,false,3361483116)}),_c('el-table-column',{attrs:{"prop":"due_time","label":"到期时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getDateformat2")(row.due_time))+" ")]}}],null,false,3332618699)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }